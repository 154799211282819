//
//
//
//
//
//

import formTable from '../components/formTable'
export default {
    components: {
        formTable,
    },
    data() {
        return {
            myData: {},
        }
    },
    mounted() {
        this.myData = { ...this.$store.state.mobile.addBillMess }
    },
}
